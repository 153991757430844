import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

function LoadingDisplay(props) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: {
          xs: "932px",
          md: "1172px",
        },
      }}
    >
      <Typography variant="h4">
        {props.text ? props.text : "Loading"}
      </Typography>
      <CircularProgress
        sx={{ p: { xs: "8px", md: "4px" }, ml: { xs: "4px", md: "8px" } }}
      />
    </Box>
  );
}

export default LoadingDisplay;
