import { createContext } from "react";
const AppContext = createContext();

export const initialState = {
  latitude: null,
  longitude: null,
  coordinates: null,
  locality: null,
  localityId: null,
  localityName: "",
  locationPermission: false,
  sortEventsBy: "time",
  hideCompletedEvents: "show",
  distance: 10,
  days: 1,
  tag: null,
  daysLabel: { 1: "Tonight", 2: "Today or Tomorrow", 7: "This Week" },
  api: {
    base: process.env.REACT_APP_FIREWORKS_API_BASE,
  },
  dropdownLocations: [],
  currentEvents: [],
  currentNotices: [],
  error: null,
  subscriptions: [],
  showFormSuccess: false,
  loadAllEvents: false,
};

export const appConsts = {
  site: {
    title: "Fireworks Tonight",
    domain: "fireworks-tonight.au",
    fqdn: process.env.REACT_APP_FIREWORKS_FQDN,
    static: "https://static.fireworks-tonight.au",
    email: "contact@fireworks-tonight.au",
    type: "webapp",
    author: "Fireworks Tonight",
    description:
      "Fireworks happening tonight? Check if there are fireworks scheduled for your or any location in Australia.",
    adPlaceholder: process.env.REACT_APP_AD_PLACEHOLDER === "Y" ? true : false,
    adsPerArticle: 2,
    theme: {
      header: {
        backgroundColor: "rgb(0, 0, 0)",
        fontColor: "#FFF",
      },
    },
    seasonalThemes: [
      {
        startDate: new Date(2024, 10, 30),
        endDate: new Date(2024, 11, 27),
        tag: "xmas24",
        color: "rgb(32, 79, 57)",
        fontColor: "#FFF",
        buttonText: "Christmas Events",
        tagLogo: "xmas24.png",
        altText: "Merry Christmas 2024",
      },
    ],
  },
  errors: {
    429: "Too many requests. Please try again shortly",
    404: "Content not found.",
    401: "Invalid Request",
    500: "An error occurred. Please try again later.",
  },
  adsense: {
    adClientId: "ca-pub-7539277489165367",
    slots: {
      banner: "8781618002",
      square: "6296600052",
      inarticle: "4854460216",
      infeed: "8665481209",
    },
  },
  reference: {
    gptName: "FireworksBot",
    gptInfoUrl: "/info/introducing-fireworks-bot",
    enhanceStates: ["QLD", "NSW"],
  },
};

export const menuLinks = {
  site: [
    {
      heading: "Site Map",
      items: [
        { title: "Home", link: "/" },
        { title: "Blog" },
        { title: "About" },
      ],
    },
  ],
  // Ensure Locations with short links are set to priority 1 in the Locations DB
  locations: [
    {
      heading: "Sydney",
      items: [
        { title: "Sydney" },
        { title: "Parramatta" },
        { title: "Darling Harbour" },
        { title: "Chatswood" },
        { title: "Sydney Olympic Park" },
        { title: "Burwood" },
        { title: "Strathfield" },
        { title: "Sutherland" },
        { title: "Penrith" },
        { title: "North Sydney" },
        { title: "Liverpool" },
      ],
    },
    {
      heading: "Queensland",
      items: [
        { title: "Gold Coast", link: "surfers-paradise" },
        { title: "Brisbane" },
        { title: "Sunshine Coast", link: "maroochydore" },
        { title: "Townsville" },
        { title: "Cairns" },
      ],
    },
    {
      heading: "New South Wales",
      items: [
        { title: "Wollongong" },
        { title: "Newcastle" },
        { title: "Byron Bay" },
        { title: "Coffs Harbour" },
      ],
    },
    {
      heading: "Western Australia",
      items: [
        { title: "Perth" },
        { title: "Fremantle" },
        { title: "Rockingham" },
        { title: "Mandurah" },
        { title: "Bunbury" },
      ],
    },
    {
      heading: "South Australia",
      items: [
        { title: "Adelaide" },
        { title: "North Adelaide" },
        { title: "Glenelg" },
        { title: "Gawler" },
        { title: "Murray Bridge" },
        { title: "Victor Harbor" },
      ],
    },
    {
      heading: "Tasmania",
      items: [
        { title: "Hobart" },
        { title: "Launceston" },
        { title: "Devonport" },
        { title: "Burnie" },
      ],
    },
    {
      heading: "Northern Territory",
      items: [{ title: "Darwin" }, { title: "Alice Springs" }],
    },
  ],
};

export default AppContext;
