import React, { useState } from "react";
import AppContext from "../AppContext";
import DispatchContext from "../DispatchContext";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";

// https://www.npmjs.com/package/react-search-autocomplete
function LocationAutocomplete(props) {
  const context = React.useContext(AppContext);
  const dispatch = React.useContext(DispatchContext);
  const [open, setOpen] = useState(false);
  const loading = open && context.dropdownLocations.length === 0;
  const [inputValue, setInputValue] = useState("");

  const getLocationData = async () => {
    if (inputValue.length >= 2) {
      const reqController = new AbortController();

      async function fetchLocationDropdown() {
        try {
          const urlPath =
            "locations?startswith=" + encodeURIComponent(inputValue);
          const locationPromise = await fetch(context.api.base + urlPath, {
            signal: reqController.signal,
          });
          const locations = await locationPromise.json();
          return locations;
        } catch {
          return;
        }
      }

      fetchLocationDropdown().then((locations) =>
        locations && locations.length > 0
          ? dispatch({
              type: "setDropdownLocations",
              value: locations,
            })
          : null
      );
    } else {
      dispatch({
        type: "setDropdownLocations",
        value: [],
      });
    }
  };

  React.useEffect(() => {
    getLocationData();
  }, [inputValue]);

  return (
    <Autocomplete
      freeSolo
      onOpen={() => {
        setOpen(true);
      }}
      loading={loading}
      loadingText="Start typing a suburb or postcode..."
      id="location-combo-box"
      options={context.dropdownLocations}
      getOptionLabel={(option) => (typeof option === "string" ? option : "")}
      value={context.rawLocality || null}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        dispatch({ type: "setLocationSelectError", value: false });
        setInputValue(newInputValue);
      }}
      sx={{ height: context.locationSelectError ? "4rem" : "3rem" }}
      renderInput={(params) => (
        <TextField
          error={context.locationSelectError ? true : false}
          helperText={
            context.locationSelectError
              ? context.locationSelectError.helperText
              : null
          }
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading && inputValue.length >= 2 ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Box
            sx={{
              textAlign: "left",
            }}
          >
            {option}
          </Box>
        </li>
      )}
      onChange={(event, newValue) => {
        dispatch({
          type: "setLocalityRaw",
          value: newValue,
        });
      }}
    />
  );
}

export default LocationAutocomplete;
