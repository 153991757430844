import * as React from "react";
import LocationSelect from "./components/LocationSelect.js";
import Unsubscribe from "./components/Unsubscribe.js";
import { useImmerReducer } from "use-immer";
import AppContext from "./AppContext";
import { initialState } from "./AppContext";
import DispatchContext from "./DispatchContext";
import { appReducerFunction } from "./appReducerFunction";
import { Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Layout } from "./Layout";
import CssBaseline from "@mui/material/CssBaseline";
import LoadingDisplay from "./components/LoadingDisplay.js";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
  components: {
    // Name of the component
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
});

const Blog = React.lazy(() => import("./components/Blog"));
const Event = React.lazy(() => import("./components/Event"));
const Fireworks = React.lazy(() => import("./components/Fireworks"));
const PrivacyAndTerms = React.lazy(() =>
  import("./components/PrivacyAndTerms.js")
);
const Contact = React.lazy(() => import("./components/Contact.js"));

export default function App() {
  const [context, dispatch] = useImmerReducer(appReducerFunction, initialState);
  const location = useLocation();

  return (
    <DispatchContext.Provider value={dispatch}>
      <AppContext.Provider value={context}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes location={location}>
            <Route path="/" element={<Layout />}>
              <Route index element={<LocationSelect />} />
              <Route path="unsubscribe" element={<Unsubscribe />} />
              <Route
                path="contact"
                element={
                  <React.Suspense
                    fallback={<LoadingDisplay text="Loading..." />}
                  >
                    <Contact />
                  </React.Suspense>
                }
              />
              <Route
                path="privacy-and-terms"
                element={
                  <React.Suspense
                    fallback={<LoadingDisplay text="Loading..." />}
                  >
                    <PrivacyAndTerms />
                  </React.Suspense>
                }
              />
              <Route
                path="info/:id"
                element={
                  <React.Suspense
                    fallback={<LoadingDisplay text="Loading..." />}
                  >
                    <Blog />
                  </React.Suspense>
                }
              />
              <Route
                path="blog/:id"
                element={
                  <React.Suspense
                    fallback={<LoadingDisplay text="Loading..." />}
                  >
                    <Blog />
                  </React.Suspense>
                }
              />
              <Route
                path="event/:id"
                element={
                  <React.Suspense
                    fallback={<LoadingDisplay text="Loading..." />}
                  >
                    <Event />
                  </React.Suspense>
                }
              />
              <Route
                path=":postcode/:locality"
                element={
                  <React.Suspense
                    fallback={<LoadingDisplay text="Loading..." />}
                  >
                    <Fireworks />
                  </React.Suspense>
                }
              />
              <Route
                path=":locality"
                element={
                  <React.Suspense
                    fallback={<LoadingDisplay text="Loading..." />}
                  >
                    <Fireworks />
                  </React.Suspense>
                }
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </AppContext.Provider>
    </DispatchContext.Provider>
  );
}
