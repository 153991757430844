import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { menuLinks, appConsts } from "./AppContext";
import { Link, Outlet } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import MuiLink from "@mui/material/Link";
import AppContext from "./AppContext";
import fireworksLogo from "./images/logo192.png";
import useMediaQuery from "@mui/material/useMediaQuery";

export function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <MuiLink color="inherit" href="https://fireworks-tonight.au/">
        Fireworks Tonight
      </MuiLink>{" "}
      {new Date().getFullYear()}
      {" | "}
      <a href={appConsts.site.fqdn + "/privacy-and-terms"}>
        Privacy Policy and Terms of Use
      </a>
      {" | "}
      <a href={appConsts.site.fqdn + "/contact"}>Contact</a>
    </Typography>
  );
}

export function LinkBox(props) {
  let leftCount = 0;
  let rightCount = 0;
  let leftLinks = [];
  let rightLinks = [];

  var linkLists = props.locations.map(function (item) {
    return (
      <LinkList
        key={item.heading.toLowerCase().replaceAll(" ", "-")}
        heading={item.heading}
        items={item.items}
      />
    );
  });

  linkLists.map(function (item) {
    if (leftCount <= rightCount) {
      leftLinks.push(item);
      leftCount = leftCount + item.props.items.length;
    } else {
      rightLinks.push(item);
      rightCount = rightCount + item.props.items.length;
    }
    return null;
  });

  return (
    <>
      <Grid xs={12} md={6}>
        {leftLinks}
      </Grid>
      <Grid xs={12} md={6}>
        {rightLinks}
      </Grid>
    </>
  );
}

function LinkList(props) {
  // If Monitor else Phone
  const linkStyle = {
    fontSize: useMediaQuery("(min-width:450px)") ? "1.125rem" : "1.35rem",
  };

  const linkItemStyle = {
    padding: useMediaQuery("(min-width:450px)") ? "0" : "0.25rem 0",
  };

  const linkHeadingStyle = {
    fontSize: useMediaQuery("(min-width:450px)") ? "1.75rem" : "2rem",
  };

  var links = props.items.map(function (item) {
    const key = item.link
      ? item.link
      : item.title.replaceAll(" ", "-").toLowerCase();

    return (
      <li key={key} style={linkItemStyle}>
        <a href={"/" + key} style={linkStyle}>
          {item.title}
        </a>
      </li>
    );
  });

  return (
    <Grid xs={12} md={6} sx={{ marginBottom: "1rem" }}>
      <Typography variant="h5" component="h2" sx={linkHeadingStyle}>
        {props.heading}
      </Typography>
      <ul>{links}</ul>
    </Grid>
  );
}

export const Layout = () => {
  const context = React.useContext(AppContext);
  const [blogLoading, setBlogLoading] = React.useState(true);
  const [blogError, setBlogError] = React.useState(null);
  const [blogList, setBlogList] = React.useState([]);
  const [infoList, setInfoList] = React.useState([]);

  // Load current theme
  const currentDate = new Date();
  const currentTheme =
    appConsts.site.seasonalThemes.find(
      (theme) => currentDate >= theme.startDate && currentDate <= theme.endDate
    ) || null;

  const [themeImage, setThemeImage] = useState(null);

  useEffect(() => {
    // Dynamic import logo based on currentTheme
    const importThemeImage = async () => {
      if (currentTheme) {
        const importedImage = await import(
          // Path cannot be a variable, but can be a variable with a static base directory
          // Due to webpack, which will index all the items in dir at build time
          "./images/" + currentTheme.tagLogo
        );
        setThemeImage(importedImage.default);
      }
    };

    importThemeImage();
  }, [currentTheme]);

  React.useEffect(() => {
    const reqController = new AbortController();

    const fetchBlogs = async () => {
      try {
        const urlPath = "blogs";

        const response = await fetch(context.api.base + urlPath + "?meta=all", {
          signal: reqController.signal,
          headers: {
            Accept: "Application/json",
          },
        });

        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let blogs = await response.json();
        setInfoList(blogs.filter((blog) => blog.meta));
        setBlogList(blogs.filter((blog) => !blog.meta));
        setBlogError(null);
      } catch (err) {
        setBlogError(err.message);
        setBlogList([]);
      } finally {
        setBlogLoading(false);
      }
    };
    fetchBlogs();
  }, [context.api.base]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container
          component="main"
          sx={{
            mt: { xs: 0, md: 1.5 },
            mb: 2,
            px: { xs: "0px", md: "1rem" },
          }}
          maxWidth="md"
        >
          <Grid container justifyContent="space-between">
            <Grid
              sx={{
                borderRadius: { xs: 0, md: 2 },
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? currentTheme
                      ? currentTheme.color
                      : appConsts.site.theme.header.backgroundColor
                    : theme.palette.grey[800],
                px: 0.5,
                py: 2,
                width: "100%",
                maxHeight: "2.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link to={"/"}>
                <Box
                  component="img"
                  width={32}
                  height={32}
                  style={{
                    display: "inline",
                    verticalAlign: "top",
                    marginRight: "4px",
                    marginTop: useMediaQuery("(min-width:370px)")
                      ? "4px"
                      : "0px",
                  }}
                  alt={
                    appConsts.site.title + " logo, an exploding firework icon."
                  }
                  src={fireworksLogo}
                />

                <Typography
                  variant="h4"
                  component="p"
                  align="left"
                  sx={{
                    height: useMediaQuery("(min-width:370px)")
                      ? "2.125rem"
                      : "1.55rem",

                    fontSize: useMediaQuery("(min-width:370px)")
                      ? "2.125rem"
                      : "1.55rem",

                    display: "inline",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? currentTheme
                          ? currentTheme.fontColor
                          : appConsts.site.theme.header.fontColor
                        : theme.palette.grey[100],
                  }}
                >
                  Fireworks Tonight
                </Typography>
              </Link>

              {currentTheme && (
                <Box
                  component="img"
                  src={themeImage}
                  width={32}
                  height={32}
                  alt={currentTheme.altText}
                  style={{
                    display: "inline",
                    verticalAlign: "top",
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              backgroundColor: "#FFF",
              borderRadius: 2,
              my: { xs: 0.5, md: 1.5 },
              mx: 0,
              px: { xs: "0.5rem", md: "1rem" },
              py: 1,
            }}
          >
            <Outlet />
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFF",
              borderRadius: 2,
              my: 2,
              mx: 0,
              px: { xs: "0.75rem", md: "1rem" },
              py: 2,
            }}
          >
            <Grid container display="flex" flexDirection="row">
              <Grid xs={12}>
                <Typography variant="h4" component="h2">
                  Popular Searches
                </Typography>
              </Grid>
              <LinkBox locations={menuLinks.locations} />

              {blogLoading && <div>A moment please...</div>}
              {blogError && <div>{`There is a problem fetching content.`}</div>}

              <Grid xs={12}>
                <Typography variant="h4" component="h2">
                  Latest Blog Posts
                </Typography>
              </Grid>
              <Grid xs={12} md={12} sx={{ marginBottom: "1rem" }}>
                <ul>
                  {blogList &&
                    blogList.map(({ id, slug, title }) => (
                      <li key={id} style={{ paddingBottom: "0.5rem" }}>
                        <a href={"/blog/" + slug}>{title}</a>
                      </li>
                    ))}
                </ul>
              </Grid>
            </Grid>

            <Grid xs={12} sx={{ marginBottom: "0.75rem" }}>
              <Typography variant="h4" component="h2">
                Links and Info
              </Typography>
              <Grid xs={12} md={6} sx={{ marginBottom: "1rem" }}>
                <ul>
                  {infoList &&
                    infoList.map(({ id, slug, title }) => (
                      <li key={id} style={{ paddingBottom: "0.5rem" }}>
                        <a href={"/info/" + slug}>{title}</a>
                      </li>
                    ))}
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box
          component="footer"
          sx={{
            py: 1,
            px: 2,
            mt: "auto",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="sm">
            <Copyright />
          </Container>
        </Box>
      </Box>
    </>
  );
};
