import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function ErrorPage(props) {
  return (
    <Typography>
      An Error occurred. Try going <Link to={"/"}>Home</Link> or if problems
      persist, try again later.
    </Typography>
  );
}
