import React, { useEffect } from "react";
import AppContext from "../AppContext";
import DispatchContext from "../DispatchContext";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, TextField, Typography } from "@mui/material";
import { useNavigate, Navigate, useSearchParams } from "react-router-dom";
import { SeoHelmet } from "./SeoHelmet";
import { upperToFriendly } from "../Utils";

function UnsubscribeForm() {
  const [searchParams] = useSearchParams();

  const context = React.useContext(AppContext);
  const dispatch = React.useContext(DispatchContext);

  const [emailDisabled, setEmailDisabled] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [email, setEmail] = React.useState(
    searchParams.get("email") ? searchParams.get("email") : ""
  );
  const [locationId, setLocationId] = React.useState(
    searchParams.get("locationId") ? searchParams.get("locationId") : null
  );

  const handleChange = (event) => {
    setEmailError(false);
    if (event.target.value.length === 0) {
      setEmail("");
    } else {
      setEmail(event.target.value);
    }
  };

  // doUnsubscribe Button Click Action
  const doUnsubscribe = async () => {
    if (email.length > 0) {
      setEmailDisabled(true);

      // Do API Call
      const reqController = new AbortController();

      async function doUnsubscribeCall() {
        try {
          const urlPath = "subscriptions";
          const subscriptionsPromise = await fetch(context.api.base + urlPath, {
            method: "POST",
            body: JSON.stringify({
              email: email,
              action: "unsubscribe",
              locationId: locationId,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            signal: reqController.signal,
          });
          const subscription = await subscriptionsPromise.json();
          return subscription;
        } catch {
          return;
        }
      }

      doUnsubscribeCall().then((unsubscription) => {
        if (unsubscription.message && unsubscription.message === "success") {
          // If Successful, note the count
          dispatch({ type: "setSubscriptions", value: null });
          dispatch({ type: "setShowFormSuccess", value: true });
        } else {
          if (unsubscription.message && unsubscription.message === "error") {
            // Handle Specific Error
            console.log(unsubscription.error);
          } else {
            // Handle Unknown Error
            console.log("Unknown Error");
          }
        }
      });
    } else {
      setEmailError(true);
    }
  };

  if (!context.showFormSuccess) {
    return (
      <>
        <Grid item key="form-location" xs={12}>
          <TextField
            variant="filled"
            disabled={emailDisabled}
            value={email}
            error={emailError}
            helperText={emailError ? "Please Enter an Email" : null}
            onChange={handleChange}
            required
            name="email"
            label="Email Address"
            fullWidth
            autoComplete="none"
          />
        </Grid>
        <Grid item key="unsub-button" xs={12}>
          <Button
            variant="contained"
            sx={{ width: "100%", fontSize: "1.25rem" }}
            size="large"
            onClick={() => {
              doUnsubscribe();
            }}
          >
            Unsubscribe
          </Button>
        </Grid>
      </>
    );
  }
}

function UnsubscribeProcessed() {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Do redirectToFireworks");
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 5000);
  }, []);

  return (
    <>
      <Typography
        sx={{
          borderRadius: 2,
          fontSize: "1.25rem",
          fontWeight: "semibold",
          textAlign: "center",
          p: 1,
          color: "#FFF",
          backgroundColor: (theme) => theme.palette.success.light,
        }}
      >
        Unsubscribe Processed Successfully.
      </Typography>
      <Typography
        sx={{
          fontSize: "0.75rem",
          textAlign: "center",
          mt: "0.5rem",
        }}
      >
        You will be redirected to the home page in 5 seconds...
      </Typography>
    </>
  );
}

function Unsubscribe() {
  const context = React.useContext(AppContext);
  const [searchParams] = useSearchParams();

  const [locationName, setLocationName] = React.useState(
    searchParams.get("locality") ? searchParams.get("locality") : null
  );

  function UnsubscribeText(props) {
    if (!context.showFormSuccess) {
      return (
        <Typography>
          This will remove the provided email address from{" "}
          {!props.locationName ? "all" : upperToFriendly(props.locationName)}{" "}
          notifications and other communicatons from this website.
          {!props.locationName
            ? " Please re-visit any Locations and re-subscribe to recreate any Notifications."
            : ""}
        </Typography>
      );
    } else {
      return <UnsubscribeProcessed />;
    }
  }

  return (
    <React.Fragment>
      <SeoHelmet recordType="text" record={{ text: "Unsubscribe" }} />
      <div>
        <Grid
          container
          justify="center"
          spacing={2}
          columnSpacing={{ xs: 0, lg: 1 }}
        >
          <Grid item key="form-prompt" xs={12}>
            <Typography sx={{ fontSize: "2rem", fontWeight: "semibold" }}>
              Unsubscribe from{" "}
              {!locationName ? "all" : upperToFriendly(locationName)}{" "}
              Notifications
            </Typography>
            <UnsubscribeText locationName={locationName} />
            <UnsubscribeForm />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default Unsubscribe;
