import { upperToFriendly } from "./Utils";

export function appReducerFunction(draft, action) {
  switch (action.type) {
    case "setLocalityName":
      draft.localityName = upperToFriendly(action.value);
      return;
    case "setLocalityId":
      draft.localityId = action.value;
      return;
    case "setLatitude":
      draft.latitude = action.value;
      return;
    case "setLongitude":
      draft.longitude = action.value;
      return;
    case "setCoordinates":
      draft.latitude = action.value.latitude;
      draft.longitude = action.value.longitude;
      draft.coordinates = {
        latitude: action.value.latitude,
        longitude: action.value.longitude,
      };
      return;
    case "setLocality":
      if (action.value) {
        draft.localityId = action.value.id;
        draft.localityName = upperToFriendly(action.value.locality);
        draft.coordinates = {
          latitude: action.value.coordinates.latitude,
          longitude: action.value.coordinates.longitude,
        };
        switch (action.value.electorate_rating) {
          case "Outer Metropolitan":
          case "Inner Metropolitan":
            draft.localityType = "City";
            break;
          default:
            draft.localityType = "Country";
        }
        draft.state = action.value.state;
        draft.postcode = action.value.postcode;
        draft.priorityLocality = action.value.priority;
      } else {
        delete draft.localityId;
        delete draft.localityName;
        delete draft.localityType;
        delete draft.coordinates;
        delete draft.state;
        delete draft.postcode;
        delete draft.priorityLocality;
      }
      return;
    case "setLocationPermission":
      draft.locationPermission = action.value;
      return;
    case "setDropdownLocations":
      draft.dropdownLocations = action.value;
      return;
    case "setDays":
      draft.days = action.value;
      return;
    case "setTag":
      draft.tag = action.value;
      return;
    case "setLocalityRaw":
      draft.localityRaw = action.value;
      return;
    case "setSortEventsBy":
      draft.sortEventsBy = action.value;
      return;
    case "setHideCompletedEvents":
      draft.hideCompletedEvents = action.value;
      return;
    case "setCurrentEvents":
      draft.currentEvents = action.value;
      return;
    case "setCurrentNotices":
      draft.currentNotices = action.value;
      return;
    case "setLocationSelectError":
      if (action.value) {
        draft.locationSelectError = { state: true, helperText: action.value };
      } else {
        draft.locationSelectError = null;
      }
      return;
    case "setSubscriptions":
      draft.subscriptions = action.value;
      return;
    case "setShowFormSuccess":
      draft.showFormSuccess = action.value;
      return;
    case "setLoadAllEvents":
      draft.loadAllEvents = action.value;
      return;
    default:
      return;
  }
}

export default appReducerFunction;
