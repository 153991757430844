export function upperToFriendly(upperstring, options = {}) {
  let splitBy = " ";
  if (options.splitter !== undefined) {
    splitBy = options.splitter.toString();
  }
  const stringArray = upperstring.toLowerCase().split(splitBy);
  const formattedArray = stringArray.map((piece) => {
    return piece[0].toUpperCase() + piece.substring(1);
  });
  return formattedArray.join(" ");
}

// Helper Function to return an object with today pieces, tomorrow and a date to indicate things in the past
export function getNow() {
  function searchableDate(y, m, d) {
    return [
      y,
      m.toString().padStart(2, "0"),
      d.toString().padStart(2, "0"),
    ].join("-");
  }

  // Pass month as 0-indexed int to get the MM equivalent
  function readableMonth(month) {
    let dateMonthInt = parseInt(month) + 1;
    return dateMonthInt.toString().padStart(2, "0");
  }

  function todayTomorrow(date) {
    var result = new Date(date);
    result.setDate(result.getDate() + 1);
    return result;
  }

  function todayOld(date) {
    var result = new Date(date);
    result.setDate(result.getDate() - 1);
    return result;
  }

  let now = {};
  let date = new Date();

  now.day = date.getDate();

  // Create the Full Date String in Local Timezone For Comparison
  now.date = searchableDate(
    date.getFullYear(),
    readableMonth(date.getMonth()),
    now.day
  );
  now.hour = new Date().getHours();

  // Get Tomorrow as well
  let todayPlusOne = todayTomorrow(date);

  now.tomorrow = searchableDate(
    todayPlusOne.getFullYear(),
    readableMonth(todayPlusOne.getMonth()),
    todayPlusOne.getDate()
  );

  // And the marker for old dates/events
  let oldDate = todayOld(date);

  now.old = searchableDate(
    oldDate.getFullYear(),
    readableMonth(oldDate.getMonth()),
    oldDate.getDate()
  );

  return now;
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export function calcCrow(userCoordinates, targetCoordinates) {
  if (!userCoordinates || !targetCoordinates) {
    return null;
  }

  var lat1 = userCoordinates.latitude;
  var lon1 = userCoordinates.longitude;

  var lat2 = targetCoordinates.latitude;
  var lon2 = targetCoordinates.longitude;

  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}
