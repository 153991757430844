import React from "react";

import { Helmet } from "react-helmet-async";
import { upperToFriendly } from "../Utils";
import AppContext, { appConsts } from "../AppContext";

export const SeoHelmet = ({ recordType = null, record = {} }) => {
  const context = React.useContext(AppContext);

  function seoDynamicContent(recordType = null, record = {}) {
    // Limit to 50-60 Chars if possible
    var title = "";
    // Limit to 160 Chars if possible
    var description = "";
    // For Canonical Links on Location Pages
    var canonicalLinkHref = null;

    switch (recordType) {
      case "event":
        if (record) {
          var date = new Date(record.date);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
          };

          title =
            record.name + " in " + upperToFriendly(record.location.locality);
          if (context.state) {
            title += ", " + context.state;
          }
          title += " on " + date.toLocaleString("en-AU", options);

          description =
            "Start time, location and all the other fireworks details for " +
            record.name +
            " in " +
            upperToFriendly(record.location.locality) +
            " on " +
            date.toLocaleString("en-AU", options);

          canonicalLinkHref = "/event/" + record.id;
        } else {
          title = "Events";
          description =
            "Times, location and all the other fireworks details for events in Australia.";
        }
        break;
      case "fireworks":
        if (record) {
          title = upperToFriendly(context.localityName);
          if (context.state) {
            title += ", " + context.state;
          }
          description =
            "See all the current and upcoming fireworks in " + title;
          if (record.priorityLocality) {
            canonicalLinkHref =
              "/" + context.localityName.replace(/\s+/g, "-").toLowerCase();
          } else {
            canonicalLinkHref =
              "/" +
              record.postcode +
              "/" +
              context.localityName.replace(/\s+/g, "-").toLowerCase();
          }
        } else {
          title = "Fireworks";
        }
        break;
      case "blog":
        title = record.title;
        description = record.summary;
        var canonicalPath = "blog";
        if (record.meta) {
          canonicalPath = "info";
        }
        canonicalLinkHref = "/" + canonicalPath + "/" + record.slug;
        break;
      case "text":
        title = record.title;
        description = record.description;
        break;
      default:
        title = null;
        description =
          "See all the times, location and details for firework events in Australia.";
    }

    if (title) {
      title += " | " + appConsts.site.title;
    } else {
      title = appConsts.site.title;
    }

    if (!description) {
      description = appConsts.site.description;
    }

    return [title, description, canonicalLinkHref];
  }

  function adsAndAnalytics() {
    // If Ad Placeholders is false
    // Include the Ads JS from Google
    if (process.env.REACT_APP_AD_PLACEHOLDER === "N") {
      return (
        <>
          <meta
            name="google-adsense-account"
            content="ca-pub-7539277489165367"
          ></meta>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7539277489165367"
            crossorigin="anonymous"
          ></script>
        </>
      );
    } else {
      return <></>;
    }
  }

  const [title, description, canonicalLinkHref] = seoDynamicContent(
    recordType,
    record
  );

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={appConsts.site.type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:creator" content={appConsts.site.author} />
      <meta name="twitter:card" content={appConsts.site.type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {adsAndAnalytics()}
      {canonicalLinkHref && (
        <link rel="canonical" href={appConsts.site.fqdn + canonicalLinkHref} />
      )}
    </Helmet>
  );
};
