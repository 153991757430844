import React from "react";
import AppContext from "../AppContext";

import DispatchContext from "../DispatchContext";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function TimeSelect() {
  const context = React.useContext(AppContext);
  const dispatch = React.useContext(DispatchContext);

  const handleChange = (event) => {
    dispatch({ type: "setDays", value: event.target.value });
  };

  const menuItems = Object.keys(context.daysLabel).map(function (index, key) {
    return (
      <MenuItem key={key} value={index}>
        {context.daysLabel[index]}
      </MenuItem>
    );
  });

  return (
    <div>
      <FormControl
        sx={{
          m: 0,
          width: "100%",
          height: "3rem",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Select
          id="time-select"
          required
          value={context.days}
          onChange={handleChange}
          default={1}
        >
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
}
