import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";
import StandardErrorBoundary from "./components/StandardErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));

//Initialize GA4 Analytics
if (process.env.REACT_APP_ENABLE_G4 === "Y") {
  ReactGA.initialize("G-81V6V9ZC2X");
}

root.render(
  <React.StrictMode>
    <StandardErrorBoundary>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </StandardErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
